import { UilCheck } from '@iconscout/react-unicons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  NumberField,
  FormLabel,
  FormGroup,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  DatePicker,
  Notification,
  Paper,
} from '@xbotvn/mui';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  useDispatch, useSelector,
} from 'react-redux';

import { paginationApi } from '../../../api';
import PaginationClient from '../../../components/PaginationClient';
import SingleSelect from '../../../components/SingleSelect';
import Table from '../../../components/Table';
import {
  filter,
  getOr,
  map,
  forEach,
  keys,
} from '../../../libs/lodash';
import {
  DOCUMENT,
  CATEGORY,
  LISTING,
} from '../../../redux/actions/constants';

import ExportDocument from './ExportDocumentCode';
import ListingModal from './ListingModal';

const styleFrom = {
  display: 'inline-table',
  width: '25%',
};

export default function DocumentEditor({
  data,
  onClose,
}) {
  const dispatch = useDispatch();

  const {
    categories,
    authors,
    producers,
    types,
    warehouses,
    storages,
    borrows,
    unitId,
  } = useSelector(({
    category,
    author,
    producer,
    type,
    warehouse,
    storage,
    borrow,
    user,
  }) => ({
    categories: category.data,
    authors: author.data,
    producers: producer.data,
    types: type.data,
    warehouses: warehouse.data,
    storages: storage.data,
    borrows: borrow.data,
    unitId: user.activeUnit,
  }));

  const [doc, setDoc] = useState(data);
  const [pageInfo, setPageInfo] = useState([]);
  const [pageSts, setPageSts] = useState([]);
  const [items, setItems] = useState([]);
  const [pageItem, setPageItem] = useState([]);
  const [isOpenDocumentCode, setIsOpenDocumentCode] = useState(false);
  const [isOpenListingsModal, setIsOpenListingsModal] = useState(false);
  const [isExportAll, setIsExportAll] = useState(true);
  const [selected, setSelected] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    paginationApi.getPage('documentItem', {
      condition: {
        unitId,
        documentId: data._id,
      },
    }).then(({ data: result }) => {
      setItems(result.records);
    });
  }, [data]);

  const filtered = useMemo(() => {
    if (selectedDate && items.length) {
      const newItems = items.filter(({ createdAt }) => moment(createdAt).format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY'));
      return newItems;
    }
    return items;
  }, [selectedDate, items]);

  const renderPaginationItems = useMemo(() => (
    <PaginationClient
      rows={filtered}
      onChange={({
        activeRows,
      }) => setPageItem(activeRows)}
    />
  ), [filtered]);

  const colsItem = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 50,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      id: 'add',
      style: {
        width: 50,
      },
      Header: () => (
        <Checkbox
          label="Tất cả"
          checked={isExportAll}
          onChange={() => setIsExportAll(!isExportAll)}
        />
      ),
      Cell: (rowProps) => {
        const value = get(rowProps, 'row');
        const check = selected.includes(value.original._id);
        return (
          <Checkbox
            disabled={isExportAll}
            checked={isExportAll || check}
            onChange={() => {
              if (check) {
                setSelected((prev) => prev.filter((code) => code !== value.original._id));
              } else {
                setSelected((prev) => [...prev, value.original._id]);
              }
            }}
          />
        );
      },
    },
    {
      Header: 'Mã sách',
      accessor: '_id',
    },
    {
      Header: 'Trạng thái',
      accessor: 'borrowed',
      Cell: (rowProps) => (rowProps.value ? 'Đang mượn' : ''),
    },
    {
      Header: 'Đã xuất mã',
      Cell: (rowProps) => (get(rowProps, 'row.original.printed') ? <UilCheck /> : ''),
    },
  ], [items, isExportAll, selected]);

  const colsInfo = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 100,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Kho',
      accessor: 'warehouseId',
      style: {
        width: 300,
      },
      Cell: (rowProps) => getOr('', 'name', warehouses[rowProps.value]),
    },
    {
      Header: 'Số lượng',
      accessor: 'amount',
      style: {
        width: 150,
      },
    },
  ], [warehouses]);

  const colsStatus = useMemo(() => [
    {
      Header: 'STT',
      style: {
        width: 50,
      },
      Cell: ({
        row,
      }) => row.index + 1,
    },
    {
      Header: 'Tên người mượn',
      accessor: 'userInfo',
      style: {
        width: 300,
      },
      Cell: (rowProps) => getOr('', 'name', rowProps.value),
    },
    {
      Header: 'Ngày mượn',
      accessor: 'borrowDate',
      style: {
        width: 200,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày phải trả',
      accessor: 'requiredReturnDate',
      style: {
        width: 200,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Ngày trả thực tế',
      accessor: 'returnDate',
      style: {
        width: 200,
      },
      Cell: (rowProps) => {
        const value = get(rowProps, 'value');
        if (!value) return null;
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      Header: 'Trạng thái',
      accessor: 'status',
      style: {
        width: 200,
      },
      Cell: (rowProps) => {
        let sts = '';
        switch (getOr('', 'value', rowProps)) {
          case 'new':
            sts = 'Đang mượn';
            break;
          case 'done':
            sts = 'Đã trả';
            break;
          case 'late':
            sts = 'Trễ hẹn';
            break;
          default:
            sts = '';
            break;
        }
        return sts;
      },
    },
  ], []);

  const renderPaginationStatus = useMemo(() => (
    <PaginationClient
      rows={filter((rc) => rc.documentId === data._id, borrows)}
      onChange={({
        activeRows,
      }) => setPageSts(activeRows)}
    />
  ), [borrows]);

  const renderPaginationInfo = useMemo(() => (
    <PaginationClient
      rows={filter((rc) => rc.documentId === data._id, storages)}
      onChange={({
        activeRows,
      }) => setPageInfo(activeRows)}
    />
  ), [storages]);

  return (
    <>
      {isOpenDocumentCode && (
        <ExportDocument
          data={isExportAll
            ? filtered.map(({ _id }) => ({ _id, name: doc?.name ?? '' }))
            : filtered.filter(({ _id }) => selected.includes(_id)).map(({ _id }) => ({ _id, name: doc?.name ?? '' }))}
          onClose={() => setIsOpenDocumentCode(false)}
        />
      )}
      {isOpenListingsModal && (
        <ListingModal
          onConfirm={({ result, newListing }) => {
            if (keys(newListing).length) {
              forEach.convert({
                cap: false,
              })(
                (record, collection) => {
                  dispatch({
                    type: LISTING.handlers.create,
                    data: {
                      data: record,
                      collection,
                    },
                  });
                },
                newListing,
              );
            }
            const symbol = getOr('', [result.categoryId, 'symbol'], categories);
            const newResult = result;
            if (symbol) {
              newResult.registrationNumber = `${symbol}-${getOr(0, [newResult.categoryId, 'addTo'], categories) + 1}`;
            }
            setDoc((prev) => ({
              ...prev,
              ...newResult,
            }));
            setIsOpenListingsModal(false);
          }}
          onClose={() => setIsOpenListingsModal(false)}
        />
      )}
      <Dialog
        open
        onClose={onClose}
        maxWidth="1200px"
        fullWidth
      >
        <DialogTitle onClose={onClose}>Thêm</DialogTitle>
        <DialogContent
          sx={{
            overflowY: 'scroll',
          }}
        >
          <Paper
            elevation={6}
            sx={{
              padding: '20px',
              mt: 1,
            }}
          >
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1, ml: 0, width: '25ch' },
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextField
                value={getOr('', 'name', doc)}
                label="Tài liệu"
                required
                onChange={(e) => setDoc((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))}
              />
              <Button
                color="primary"
                onClick={() => setIsOpenListingsModal(true)}
                sx={{
                  marginTop: 4,
                }}
              >
                Nhập tay danh mục
              </Button>
              <FormControl>
                <FormControlLabel
                  label="Sách mới"
                  sx={{ marginTop: 4, marginLeft: 1 }}
                  control={(
                    <Checkbox
                      checked={doc?.new}
                      onChange={() => {
                        setDoc((prev) => ({
                          ...prev,
                          new: !doc?.new,
                        }));
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {map(
              ({
                title, dataSelector, key,
              }) => ((
                <FormGroup
                  key={key}
                  style={styleFrom}
                >
                  <SingleSelect
                    options={map(
                      ({
                        _id, name,
                      }) => ({
                        label: name, value: _id,
                      }),
                      dataSelector,
                    )}
                    value={getOr('', key, doc)}
                    onChange={(val) => {
                      if (key === 'categoryId') {
                        const symbol = getOr('', [val, 'symbol'], categories);
                        setDoc((prev) => ({
                          ...prev,
                          registrationNumber: symbol ? `${symbol}-${getOr(0, [val, 'addTo'], categories) + 1}` : '',
                        }));
                      }
                      setDoc((prev) => ({
                        ...prev,
                        [key]: val,
                      }));
                    }}
                    placeholder={`Chọn ${title}`}
                    InputProps={{
                      label: title,
                      required: true,
                    }}
                  />
                </FormGroup>
              )),
              [
                {
                  title: 'Loại sách',
                  dataSelector: types,
                  key: 'typeId',
                },
                {
                  title: 'Danh mục sách',
                  dataSelector: categories,
                  key: 'categoryId',
                },
                {
                  title: 'Tác giả',
                  dataSelector: authors,
                  key: 'authorId',
                },
                {
                  title: 'Nhà xuất bản',
                  dataSelector: producers,
                  key: 'producerId',
                },
              ],
            )}
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { width: '25ch' },
                display: 'flex',
                flexDirection: 'row',
              }}
              noValidate
              autoComplete="off"
            >
              <Box>
                <NumberField
                  value={getOr(0, 'price', doc)}
                  sx={{
                    flexGrow: 1,
                    margin: '8px 0px!important',
                  }}
                  label="Giá tiền"
                  onValueChange={({ floatValue }) => setDoc((prevValue) => ({
                    ...prevValue,
                    price: floatValue,
                  }))}
                />
                <TextField
                  value={getOr('', 'registrationNumber', doc)}
                  label="Số đăng ký cá biệt"
                  onChange={(e) => setDoc((prevValue) => ({
                    ...prevValue,
                    registrationNumber: e.target.value,
                  }))}
                  sx={{
                    flexGrow: 1,
                    margin: '8px 0px!important',
                  }}
                />
                <TextField
                  value={getOr('', 'url', doc)}
                  label="Đường link"
                  onChange={(e) => setDoc((prevValue) => ({
                    ...prevValue,
                    url: e.target.value,
                  }))}
                  sx={{
                    flexGrow: 1,
                    margin: '8px 0px!important',
                  }}
                />
              </Box>
            </Box>
            <FormGroup>
              <TextField
                multiline
                rows={5}
                label="Nội dung tóm tắt"
                placeholder="Tóm tắt để rõ"
                value={getOr('', 'content', doc)}
                onChange={(e) => setDoc((prevValue) => ({
                  ...prevValue,
                  content: e.target.value,
                }))}
              />
            </FormGroup>
          </Paper>

          <Paper
            elevation={6}
            sx={{
              padding: '20px',
              mt: 1,
            }}
          >
            {getOr('', '_id', doc)
              && (
                <FormGroup>
                  <FormLabel
                    component="p"
                    sx={{
                      fontSize: '1rem',
                      marginBottom: '8px',
                    }}
                  >
                    Danh sách mã
                  </FormLabel>
                  <Box>
                    <Box sx={{
                      '& .MuiTextField-root': { m: 1, ml: 0, width: '25ch' },
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                    >
                      <DatePicker
                        label="Lọc ngày nhập mã"
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                      />
                      <Button
                        sx={{ marginLeft: 1, marginTop: '25px' }}
                        onClick={() => setIsOpenDocumentCode(true)}
                        variant="outlined"
                        color="primary"
                      >
                        Xuất danh sách mã
                      </Button>
                    </Box>
                    <Table columns={colsItem} data={pageItem} />
                    {renderPaginationItems}
                  </Box>
                </FormGroup>
              )}
          </Paper>

          <Paper
            elevation={6}
            sx={{
              padding: '20px',
              mt: 1,
            }}
          >
            {getOr('', '_id', doc) && (
              <>
                <FormLabel component="p" sx={{ fontWeight: '800' }}>Tình trạng lưu trữ</FormLabel>
                <Table columns={colsInfo} data={pageInfo} />
                {renderPaginationInfo}
              </>
            )}
          </Paper>
          <Paper
            elevation={6}
            sx={{
              padding: '20px',
              mt: 1,
            }}
          >
            <FormLabel component="p" sx={{ fontWeight: '800' }}>Tình trạng Mượn/Trả</FormLabel>
            <Table columns={colsStatus} data={pageSts} />
            {renderPaginationStatus}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            onClick={() => {
              if (!getOr('', 'name', doc)) {
                Notification.warn('Tên tài liệu không được trống.');
                return;
              }
              if (!getOr('', 'typeId', doc)) {
                Notification.warn('Chưa chọn loại tài liệu.');
                return;
              }
              if (!getOr('', 'categoryId', doc)) {
                Notification.warn('Chưa chọn danh mục tài liệu.');
                return;
              }
              if (!getOr('', 'authorId', doc)) {
                Notification.warn('Chưa chọn Tác giả.');
                return;
              }
              if (!getOr('', 'producerId', doc)) {
                Notification.warn('Chưa chọn Nhà xuất bản.');
                return;
              }
              dispatch({
                type: DOCUMENT.handlers.update,
                data: doc,
                onSuccess: () => {
                  if (!doc._id) {
                    const cloneCategory = categories[doc.categoryId];
                    cloneCategory.addTo = getOr(0, 'addTo', cloneCategory) + 1;
                    dispatch({
                      type: CATEGORY.handlers.update,
                      data: cloneCategory,
                      onSuccess: () => {
                        console.log('Update addTo');
                      },
                    });
                  }
                  onClose();
                },
              });
            }}
          >
            Lưu
          </Button>
          <Button
            color="error"
            onClick={onClose}
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DocumentEditor.propTypes = {
  data: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
};
