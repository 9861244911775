import {
  Button,
} from '@xbotvn/mui';
import React, {
  useState,
} from 'react';

export default function LoginFacebook() {
  const [loading] = useState(false);

  return (
    <Button
      color="primary"
      loading={loading ? 'true' : undefined}
      disabled={loading}
      onClick={() => { }}
    >
      Facebook
    </Button>
  );
}
