import { UilLayerGroup, UilListOlAlt } from '@iconscout/react-unicons';
import { Toolbar, Button, Box } from '@xbotvn/mui';
import React, {
  useMemo,
  useState,
} from 'react';
import {
  useSelector,
} from 'react-redux';

import Chart from './Chart';

export default function Statistic() {
  const {
    types,
    categories,
  } = useSelector(({
    type, category,
  }) => ({
    types: type.data,
    categories: category.data,
  }));

  const [chartActive, setChartActive] = useState('category');

  const renderChart = useMemo(() => {
    switch (chartActive) {
      case 'category': return <Chart data={categories} type="categoryId" />;
      case 'type': return <Chart data={types} type="typeId" />;
      default:
        return <div />;
    }
  }, [chartActive]);

  return (
    <div style={{
      marginBottom: 50,
    }}
    >
      <Toolbar
        sx={{
          boxShadow:
            'rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        }}
      >
        <Box>
          <Button
            startIcon={<UilListOlAlt />}
            variant="text"
            color={chartActive === 'category' ? 'primary' : 'inherit'}
            onClick={() => setChartActive('category')}
          >
            Thống kê danh mục sách
          </Button>
          <Button
            startIcon={<UilLayerGroup />}
            variant="text"
            color={chartActive === 'type' ? 'primary' : 'inherit'}
            onClick={() => setChartActive('type')}
          >
            Thống kê loại sách
          </Button>
        </Box>
      </Toolbar>
      {renderChart}
    </div>
  );
}
