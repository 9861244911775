import {
  Button,
} from '@xbotvn/mui';
import PropTypes from 'prop-types';
import React from 'react';
import {
  useDispatch,
} from 'react-redux';

import {
  USER,
} from '../../../redux/actions/constants';

export default function LoginGoogle({
  user,
  onClose,
}) {
  const dispatch = useDispatch();

  return (
    <Button
      color="error"
      loading={user.handling ? 'true' : undefined}
      disabled={user.handling}
      onClick={() => {
        dispatch({
          type: USER.handlers.login,
          service: 'google',
          onSuccess: onClose,
        });
      }}
    >
      Google
    </Button>
  );
}

LoginGoogle.propTypes = {
  user: PropTypes.shape({
    handling: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
